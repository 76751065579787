@tailwind components;
@tailwind utilities;

/* This is a class that will be injected by recurly, we need this for our
    credit card forms. */
.recurly-hosted-field {
    @apply m-0 border-0 w-full h-[34px]
}

.grecaptcha-badge {
    visibility: hidden;
}

/* Aeonik */
@font-face {
    font-family: 'Aeonik';
    src: url("../fonts/aeonik/Aeonik-Bold.ttf") format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url("../fonts/aeonik/Aeonik-BoldItalic.ttf") format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url("../fonts/aeonik/Aeonik-Medium.ttf") format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url("../fonts/aeonik/Aeonik-MediumItalic.ttf") format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Aeonik';
    src: url("../fonts/aeonik/Aeonik-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Aeonik';
    src: url("../fonts/aeonik/Aeonik-RegularItalic.ttf") format('truetype');
    font-weight: 400;
    font-style: italic;
}

/* Fira Code */
@font-face {
    font-family: 'Fira Code';
    src: url("../fonts/firaCode/FiraCode-VariableFont_wght.ttf") format('truetype');
}

/* Inter */
@font-face {
    font-family: 'Inter';
    src: url("../fonts/inter/InterVariable.ttf") format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url("../fonts/inter/InterVariable-Italic.ttf") format('truetype');
    font-style: italic;
}


@layer utilities {
    .hide-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .hide-scrollbar::-webkit-scrollbar {
        @apply hidden;
    }

    .active-tab-decorator::before {
        content: " ";
        @apply w-full bg-border-tab h-1 absolute block -bottom-[1px] left-0
    }

    .bg-stripes-red {
        background: repeating-linear-gradient(135deg,
                white,
                white 10px,
                rgb(255, 253, 253) 10px,
                rgb(255, 253, 253) 20px,
            );
    }

    .focus-ring {
        @apply ring-1 ring-offset-2 ring-offset-blue
    }

    .text-heading-3xl {
        @apply text-3xl font-medium font-heading text-heading
    }

    .text-heading-2xl {
        @apply text-2xl font-medium font-heading text-heading
    }

    .text-heading-xl {
        @apply text-xl font-medium font-heading text-heading
    }

    .text-heading-lg {
        @apply text-lg font-medium font-heading text-heading
    }

    .text-title {
        @apply text-base font-medium font-heading text-heading
    }

    .text-subtitle {
        @apply text-base font-body text-secondary
    }

    .text-body {
        @apply text-base font-body text-primary
    }

    .text-body-sb {
        @apply text-body font-semibold
    }

    .text-body-sm {
        @apply text-sm font-body
    }


    .text-body-sm-sb {
        @apply text-body-sm font-semibold
    }

    .text-label {
        @apply text-xs font-bold uppercase text-secondary
    }

    .text-code {
        font-weight: 450;
        @apply text-base font-mono
    }

    .text-code-sb {
        font-weight: 450;
        @apply text-code font-semibold
    }

    .text-code-sm {
        font-weight: 450;
        @apply text-sm font-mono
    }

    .text-code-sm-sb {
        @apply text-code-sm font-semibold
    }
    
    .icon-primary {
        @apply text-icon-primary
    }

    .icon-secondary {
        @apply text-icon-secondary
    }

    .icon-success {
        @apply text-icon-success
    }

    .icon-error {
        @apply text-icon-error
    }

    .icon-warning {
        @apply text-icon-warning
    }

    .icon-change {
        @apply text-icon-change
    }

    .icon-info {
        @apply text-icon-info
    }

    .icon-beta {
        @apply text-icon-beta
    }

    .icon-disabled {
        @apply text-icon-disabled
    }

    .icon-inverted {
        @apply text-icon-inverted
    }

    .icon-white {
        @apply text-icon-white
    }
}
